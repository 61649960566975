export default {
  "key_ComingSoon": "Скоро будет..",

  "key_Rub": "руб",

  "key_Help": "Помощь",
  "key_TermsAndConditions": "Условия использования",
  "key_PrivacyPolicy": "Политика конфиденциальности",

  // -- Preloader
  "key_Preloader_PleaseWait": "Пожалуйста, подождите немного, пока мы готовим контент!",

  // -- Buttons
  "key_Update": "Обновить",
  "key_Remove": "Удалить",
  "key_Save": "Сохранить",
  "key_Duplicate": "Дублировать",
  "key_Disable": "Отключить",
  "key_Enable": "Включить",
  "key_Notify": "Уведомить",
  "key_Restart": "Перезагрузить",
  "key_Create": "Создать",
  "key_Cancel": "Отменить",
  "key_Add": "Добавить",
  "key_AddGroup": "Добавить группу",
  "key_AddRule": "Добавить правило",
  "key_Apply": "Применить",
  "key_Execute": "Выполнить",
  "key_Close": "Закрыть",
  "key_Select": "Выбрать",
  "key_Change": "Изменить",
  "key_Confirm": "Подтвердить",
  "key_Back": "Назад",
  "key_Connect": "Подключить",
  "key_Disconnect": "Отключить",
  "key_SignIn": "Войти в систему",
  "key_SignUp": "Создать аккаунт",
  "key_Send": "Отправить",
  "key_Login": "Авторизоваться",
  "key_LogOut": "Выйти",
  "key_Recovery": "Восстановить",
  "key_Upload": "Загрузить",
  "key_Clear": "Очистить",
  "key_Online": "В сети",
  "key_Offline": "Не в сети",
  "key_Info": "Инфо",
  "key_Events": "Эвенты",
  "key_Search": "Поиск",
  "key_ExportToExcel": "Экспорт",
  "key_ShowMore": "Подробнее",

  "key_StatusMediaError": "Ошибка",
  "key_StatusMediaReady": "Загружен",
  "key_StatusMediaUploading": "Загрузка",
  "key_StatusMediaPreparing": "Подготовка",
  "key_StatusMediaDeleting": "Удаление",
  "key_StatusMediaUnknown": "Неопределен",

  "key_StatusMediaCreated": "Новый",
  "key_StatusMediaAccepted": "Принят",
  "key_StatusMediaRejected": "Отклонен",

  "key_NewTransaction": "Добавить транзакцию",
  "key_CustomerBalance": "Текущий баланс",

  "key_MediaPlans": "Медиа планы",
  "key_MediaPlan": "Медиа план",
  "key_AdvertisingCampaigns": "Рекламные кампании",
  "key_AdvertisingCampaign": "Рекламная кампания",
  "key_Medias": "Список медиа",
  "key_Media": "Медиа",
  "key_Balance": "Баланс",
  "key_CurrentMediaSource": "Исходное медиа",
  "key_Preset": "Пресет",
  "key_ResultMediaSource": "Результирующее медиа",
  "key_MediaEditor": "Редактор медиа",
  "key_PresetCommand": "Команда",

  "key_Home": "Главная",
  "key_Tags": "Теги",
  "key_Transactions": "Транзакции",
  "key_Customers": "Покупатели",
  "key_Partners": "Продавцы",
  "key_Devices": "Устройства",
  "key_Profile": "Профайл",
  "key_Dashboard": "Информационная панель",
  "key_Settings": "Настройки",

  // Errors
  "key_errorMsg_VideoNotSupportedInBrowser": "Видео не поддерживается браузером",
  "key_errorMsg_BadFFmpegCommand": "Формат комманды не верный",
  "key_errorMsg_VideoNotUploaded": "Воспроизведение будет доступно, после успешной загрузки",
  "key_errorMsg_FORBIDDEN": "Доступ запрещен",
  "key_errorMsg_INVALID_INCOMING_JSON": "Неверные входящие данные",
  "key_errorMsg_BadRequest": "Плохой запрос",
  "key_errorMsg_BadEmailParameter": "Неверный параметр email",
  "key_errorMsg_BadHostParameter": "Неверный параметр сервер",
  "key_errorMsg_BadLoginParameter": "Неверный параметр логин",
  "key_errorMsg_BadUsernameParameter": "Неверный параметр username",
  "key_errorMsg_BadNameParameter": "Неверный параметр имени",
  "key_errorMsg_BadCommandParameter": "Неверный параметр команды",
  "key_errorMsg_BadPeriodParameter": "Неверный параметр период",
  "key_errorMsg_BadEventParameter": "Неверный параметр эвент",
  "key_errorMsg_BadConditionParameter": "Неверный параметр условие",
  "key_errorMsg_BadValueParameter": "Неверный параметр значение",
  "key_errorMsg_BadUnitParameter": "Неверный параметр единица",
  "key_errorMsg_BadPasswordParameter": "Неверный параметр пароля",
  "key_errorMsg_BadSenderNameParameter": "Неверный параметр имя отправителя",
  "key_errorMsg_BadSenderEmailParameter": "Неверный параметр почта отправителя",
  "key_errorMsg_BadInviteCodeParameter": "Пригласительный код невалиден",
  "key_errorMsg_USERNAME_CANNOT_BE_BLANK": "Имя пользователя не может быть пустым",
  "key_errorMsg_USERNAME_OR_EMAIL_INVALID": "Неверный юзернэйм или email",
  "key_errorMsg_USERNAME_EXISTS": "Неверное имя пользователя или адрес электронной почты",
  "key_errorMsg_USERNAME_FORMAT_INVALID": "Имя пользователя может содержать только буквенно-цифровые символы или подчеркивание и не может начинаться или заканчиваться подчеркиванием",
  "key_errorMsg_USERNAME_TO_SHORT": "Имя пользователя слишком короткое (минимум 4 символа)",
  "key_errorMsg_USERNAME_TO_LONG": "Имя пользователя слишком длинное (максимум 64 символа)",
  "key_errorMsg_EMAIL_EXISTS": "Адрес электронной почты уже существует",
  "key_errorMsg_EMAIL_DOES_NOT_EXISTS": "Электронная почта не существует",
  "key_errorMsg_EMAIL_CANNOT_BE_BLANK": "Поле электронной почты не может быть пустым",
  "key_errorMsg_EMAIL_FORMAT_INVALID": "Неверный формат электронной почты",
  "key_errorMsg_PASSWORD_CANNOT_BE_BLANK": "Пароль не может быть пустым",
  "key_errorMsg_PASSWORD_FORMAT_INVALID": "Неверный формат пароля",
  "key_errorMsg_PASSWORDS_ARE_EQUAL": "Новый и текущий пароль совпадают",
  "key_errorMsg_PASSWORDS_NOT_EQUAL": "Пароли не совпадают",
  "key_errorMsg_PASSWORD_TO_SHORT": "Пароль слишком короткий (минимум 8 символов)",
  "key_errorMsg_PASSWORD_NOT_VALID": "Неверный пароль",
  "key_errorMsg_NAME_ALREADY_EXISTS": "Имя уже существует",
  "key_errorMsg_NAME_CANNOT_BE_BLANK": "Имя не может быть пустым",
  "key_errorMsg_NAME_FORMAT_INVALID": "Имя может содержать только буквенно-цифровые символы или одиночные дефисы или подчеркивания и не может начинаться или заканчиваться дефисом или подчеркиванием",
  "key_errorMsg_NAME_TO_SHORT": "Имя слишком короткое (минимум 4 символа)",
  "key_errorMsg_NAME_TO_LONG": "Имя слишком длинное (максимум 64 символа)",
  "key_errorMsg_INVALID_REDIRECT_URI": "Неверный редирект URI",
  "key_errorMsg_INVALID_FIELD_VALUE": "Неверное значение поля",
  "key_errorMsg_FIELD_CANNOT_BE_BLANK": "Поле не может быть пустым",
  "key_errorMsg_INTERNAL_SERVER_ERROR": "Внутренняя ошибка сервера",
  "key_errorMsg_UNDEFINED": "Неизвестная ошибка",
  "key_errorMsg_LOGIN_FAILED": "Неправильный логин",
  "key_errorMsg_PASSWORD_FAILED": "Неверный пароль",
  "key_errorMsg_SIGNIN_FAILED": "Неверный логин или пароль",
  "key_errorMsg_SIGNUP_FAILED": "Неверный логин или адрес электронной почты или пароль",
  "key_errorMsg_UNAUTHORIZED": "Вы должны быть зарегистрированы",
  "key_errorMsg_BAD_REQUEST": "Ваш браузер отправил запрос, который этот сервер не может понять",
  "key_errorMsg_NOT_FOUND": "Не найдено",
  "key_errorMsg_NOT_IMPLEMENTED": "Не реализована",
  "key_errorMsg_NOT_ACCEPTABLE": "Неверный параметр",
  "key_errorMsg_BAD_GATEWAY": "Плохой шлюз",
  "key_errorMsg_FIELD_TOTAL_LESS_FIELD_DAILY": "Общее количество не может быть меньше повседневного",
  "key_errorMsg_INCORRECT_DATE_OR_TIME": "Неверная дата или время",
  "key_errorMsg_BadDomainParameter": "Неверный параметр domain",
  "key_errorMsg_BadHostnameParameter": "Неверный параметр hostname",
  "key_errorMsg_BadPublicIpParameter": "Неверный параметр public IP",
  "key_errorMsg_BadCaCertParameter": "Неверный параметр ca.cert",
  "key_errorMsg_BadCaKeyParameter": "Неверный параметр ca.key",
  "key_errorMsg_BadServerCertParameter": "Неверный параметр server.cert",
  "key_errorMsg_BadServerKeyParameter": "Неверный параметр server.key",
  "key_errorMsg_BadTaKeyParameter": "Неверный параметр ta.key",
  "key_errorMsg_NotUniqueNameParameter": "Такое название уже существует",
  "key_errorMsg_BadStatusParameter": "Неверный параметр - статус",
  "key_errorMsg_BadOrientationParameter": "Неверный параметр - формат",
  "key_errorMsg_BadFirstNameParameter": "Неверный параметр - имя",
  "key_errorMsg_BadLastNameParameter": "Неверный параметр - фамилия",
  "key_errorMsg_BadPhoneParameter": "Неверный параметр - телефон",
  "key_errorMsg_BadItnParameter": "Неверный параметр - ИНН",
  "key_errorMsg_BadIecParameter": "Неверный параметр - КПП",
  "key_errorMsg_BadPsrnParameter": "Неверный параметр - ОГРН",
  "key_errorMsg_BadBicParameter": "Неверный параметр - БИК",
  "key_errorMsg_BadActualAddressParameter": "Неверный параметр - фактический адрес",
  "key_errorMsg_BadLegalAddressParameter": "Неверный параметр - юридический адрес",
  "key_errorMsg_BadCeoParameter": "Неверный параметр - гениральный директор",
  "key_errorMsg_BadBankParameter": "Неверный параметр - название банка",
  "key_errorMsg_BadCurrentAccountParameter": "Неверный параметр - расчетный счет",
  "key_errorMsg_BadCorrespondingAccountParameter": "Неверный параметр - корреспондентский счет",

  "key_errorMsg_BadTriggerNameParameter": "Название триггера не может быть пустым",
  "key_errorMsg_BadTriggerActionParameter": "Не выбрано действие триггера",
  "key_errorMsg_BadTriggerEntityParameter": "Не выбрано сущность триггера",
  "key_errorMsg_BadTriggerValueEmailParameter": "Не заполнено значение email",
  "key_errorMsg_BadTriggerConditionsLengthParameter": "Список условий не может быть пустым",
  "key_errorMsg_BadTriggerConditionsStatusParameter": "Поле статус не заполнено",
  "key_errorMsg_BadTriggerConditionsEventParameter": "Поле эвент  не заполнено",
  "key_errorMsg_BadTriggerConditionsValueParameter": "Поле значение  не заполнено",
  "key_errorMsg_BadTriggerConditionsConditionParameter": "Поле условие не заполнено",

  // -- AccountPartialSignIn
  "key_AccountPartialSignIn_Description": "Платформа управления администраторами ADS",

  // -- AccountFormSignIn
  "key_AccountFormSignIn_LabelLogin": "Почтовый адрес",
  "key_AccountFormSignIn_PlaceholderLogin": "Почтовый адрес",
  "key_AccountFormSignIn_LabelPassword": "Пароль",
  "key_AccountFormSignIn_PlaceholderPassword": "Пароль",
  "key_AccountFormSignIn_ForgotPassword": "Забыли пароль?",
  "key_AccountFormSignIn_CreateAccount": "Создать аккаунт",

  // -- AccountFormSignUp
  "key_AccountFormSignUp_LabelUsername": "Логин",
  "key_AccountFormSignUp_PlaceholderUsername": "Логин",
  "key_AccountFormSignUp_LabelEmail": "Почтовый адрес",
  "key_AccountFormSignUp_PlaceholderEmail": "Почтовый адрес",
  "key_AccountFormSignUp_LabelPassword": "Пароль",
  "key_AccountFormSignUp_PlaceholderPassword": "Пароль",
  "key_AccountFormSignUp_ButtonCreateAccount": "Создать аккаунт",
  "key_AccountFormSignUp_SigningUpOrSigningUp": "Регистрация означает, что вы прочитали и согласны с Условиями обслуживания и нашей Политикой конфиденциальности.",

  // -- AccountPartialSignUp
  "key_AccountFormSignUp_Description": "ADS admin management platform",
  "key_AccountFormSignUp_IHaveAccount": "Я уже имею аккаунт",

  // -- AccountPartialSignUp
  "key_AccountPartialSignUp_Description": "Платформа управления авьштфми ADS",

  // -- Header
  "key_Header_TabItemDevicesMenu": "Устройства",
  "key_Header_TabItemPartnerMenu": " Продавцы",
  "key_Header_TabItemCustomerMenu": "Покупатели",
  "key_Header_Dropdown_Settings": "Настройки",

  // -- AccountHeader
  "key_AccountHeader_ManageAccount": " Управление аккаунтом",
  "key_AccountHeader_TabProfile": "Аккаунт",

  // -- AccountPartialGeneral
  "key_AccountPartialGeneral_Profile": "Профайл",
  "key_AccountPartialGeneral_YorEmailIsIdentity": "Ваш электронный адрес является вашей личностью и используется для входа.",
  "key_AccountPartialGeneral_Password": "Пароль",
  "key_AccountPartialGeneral_ChangingPassword": "Изменение пароля также приведет к сбросу ключа API.",
  "key_AccountPartialGeneral_SmtpServer": "Почтовый сервер",
  "key_AccountPartialGeneral_SmtpServerDesc": "Настройка почтового сервера для отправки уведомлений.",
  "key_AccountPartialGeneral_Language": "Язык",
  "key_AccountPartialGeneral_ChangeLanguage": "Изменение языка",

  // -- AccountFormProfile
  "key_AccountFormProfile_LabelEmailAddress": "Адрес электронной почты",
  "key_AccountFormProfile_PlaceholderEmailAddress": "Адрес электронной почты",
  "key_AccountFormProfile_LabelName": "Имя",
  "key_AccountFormProfile_PlaceholderName": "Имя",
  "key_AccountFormProfile_LabelBio": "О вас",
  "key_AccountFormProfile_PlaceholderBio": "О вас",

  // -- AccountFormPasswordChange
  "key_AccountFormPasswordChange_LabelCurrentPassword": "Текущий пароль",
  "key_AccountFormPasswordChange_PlaceholderCurrentPassword": "Текущий пароль",
  "key_AccountFormPasswordChange_LabelEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordChange_PlaceholderEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordChange_LabelConfirmPassword": "Повторный ввод пароля",
  "key_AccountFormPasswordChange_PlaceholderConfirmPassword": "Повторный ввод пароля",

  // -- AccountPartialRecovery_RecoveryPassword
  "AccountPartialRecovery_RecoveryPassword": "Восстановление пароля",

  // -- AccountFormPasswordRecovery
  "key_AccountFormPasswordRecovery_LabelEmail": "Электронная почта",
  "key_AccountFormPasswordRecovery_PlaceholderEmail": "Электронная почта",
  "key_AccountFormPasswordRecovery_SendRecoveryEmailButton": "Отправить письмо для восстановления",
  "key_AccountFormPasswordRecovery_LabelConfirmPassword": "Подтвердите новый пароль",
  "key_AccountFormPasswordRecovery_PlaceholderConfirmPassword": "Подтвердите новый пароль",
  "key_AccountFormPasswordRecovery_LabelEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordRecovery_PlaceholderEnterNewPassword": "Введите новый пароль",

  // -- PageRecovery
  "key_PageRecovery_PasswordRecovery": "Восстановление пароля",
  "key_PageRecovery_MessageSent": "На вашу почту было отправлено сообщение",
  "key_PageRecovery_ReturnToSignIn": "вернуться на страницу входа",
  "key_PageRecovery_BackToLogin": "Вернуться на страницу авторизации",

  // -- PageNotFound
  "key_PageNotFound_404PageNotFound": "404 страница не найдена",
  "key_PageNotFound_WeAreSorry": "К сожалению, страница, которую вы ищете, не существует.",
  "key_PageNotFound_GoToHOME": "ВЕРНУТЬСЯ ДОМОЙ",

  // -- MultiFileUploader
  "MultiFileUploader_DragAndDropDescription": "Перетащите сюда несколько файлов или нажмите, чтобы выбрать файлы",

  // -- key_TagCardList
  "key_TagCardList_TableHeaderName": "Название",
  "key_TagCardList_TableHeaderCreated": "Дата создания",

  // -- key_TagSelectableCardList
  "key_TagSelectableCardList_TableHeaderName": "Название",
  "key_TagSelectableCardList_TableHeaderCreated": "Дата создания",

  // -- SettingsPageInfo
  "key_SettingsPageInfo_TableUpdateContentTime": "Интервал обновления контента на устройстве (в минутах)",
  "key_SettingsPageInfo_TableUpdateDefaultContent": "Контент по умолчанию",
  "key_SettingsPageInfo_TableUpdateDefaultContentForUpload": "Контент по умолчанию для загрузки",
  "key_SettingsPageInfo_RemoveApproveQuestions": "Вы уверены что хотите удалить?",

  // -- DevicePageList
  "key_DevicePageList_AddNewDevice": " Добавить устройство",

  // -- DeviceCardList
  "key_DeviceCardList_TableHeaderStatus": "Статус",
  "key_DeviceCardList_TableHeaderNumber": "Номер",
  "key_DeviceCardList_TableHeaderVersion": "Версия",
  "key_DeviceCardList_TableHeaderSystemStatus": "Системный статус",
  "key_DeviceCardList_TableHeaderTv": "TV",
  "key_DeviceCardList_TableHeaderBrowser": "Browser",
  "key_DeviceCardList_TableHeaderRules": "Rules",
  "key_DeviceCardList_TableHeaderCpuTemperature": "°C",
  "key_DeviceCardList_TableHeaderVpnIP": "VPN IP",
  "key_DeviceCardList_TableHeaderAddress": "Адрес",
  "key_DeviceCardList_TableHeaderName": "Название",
  "key_DeviceCardList_TableHeaderPartner": "Продавец",
  "key_DeviceCardList_TableHeaderPartnerAndAddress": "Продавец и адрес",
  "key_DeviceCardList_TableHeaderDetails": "Детали",
  "key_DeviceCardList_TableHeaderUpdated": "Обновлялся",

  // -- PartnerPageList
  "key_PartnerPageList_AddNewPartner": "Добавить покупателя",

  // -- key_PartnerCardList
  "key_PartnerCardList_TableHeaderName": "Продавец",
  "key_PartnerCardList_TableHeaderMediaCounts": "Медиа (шт)",
  "key_PartnerCardList_TableHeaderMPCounts": "Медиа план (шт)",

  // -- CustomerPageList
  "key_CustomerPageList_AddNewCustomer": "Добавить клиента",

  // -- key_CustomerCardList
  "key_CustomerCardList_TableHeaderName": "Покупатель",
  "key_CustomerCardList_TableHeaderOrganization": "Организация",
  "key_CustomerCardList_TableHeaderMediaCounts": "Медиа (шт)",
  "key_CustomerCardList_TableHeaderADCounts": "Рекламная кампания (шт)",
  "key_CustomerCardList_TableHeaderBalance": "Баланс (руб)",

  // -- PageProjectCreate
  "key_PageDeviceCreate_NewDeviceTitle": "Новое устройство",

  // -- DeviceFormCreate
  "key_DeviceFormCreate_LabelName": "Название устройства",
  "key_DeviceFormCreate_PlaceholderName": "Название устройства",
  "key_DeviceFormCreate_LabelSerialName": "Серийный номер устройства",
  "key_DeviceFormCreate_PlaceholderSerialName": "Серийный номер устройства",
  "key_DeviceFormCreate_LabelAddress": "Адрес устройства",
  "key_DeviceFormCreate_PlaceholderAddress": "Адрес устройства",
  "key_DeviceFormCreate_ButtonCreate": "Добаавить устройство",
  "key_DeviceFormCreate_LabelTlsConfiguration": " Включить TLS",
  "key_DeviceFormCreate_TlsCATitle": "CA сертификат (.crt, .pem)",
  "key_DeviceFormCreate_TlsCertTitle": "SSL сертификат (.crt, .pem)",
  "key_DeviceFormCreate_TlsKeyTitle": "SSL ключ (.key, .pem)",

  // -- PageDeviceInfo
  "key_PageDeviceInfo_TabInfo": "Инфо",
  "key_PageDeviceInfo_TabSchedule": "Расписание",
  "key_PageDeviceInfo_TabSettings": "Настройки",

  "key_PageDeviceInfo_FormDate_Name": "Дата",
  "key_PageDeviceInfo_FormDate_StartDatePlaceholder": "От",
  "key_PageDeviceInfo_FormDate_EndDatePlaceholder": "До",
  "key_PageDeviceInfo_FormDate_Today": "Сегодня",
  "key_PageDeviceInfo_FormDate_ThisMonth": "Этот месяц",
  "key_PageDeviceInfo_FormDateButton_Name": "Выбрать",
  "key_PageDeviceInfo_FormType_Name": "Тип",
  "key_PageDeviceInfo_FormTypeSelect_All": "Все",
  "key_PageDeviceInfo_FormTypeSelect_Command": "Команда",
  "key_PageDeviceInfo_FormTypeSelect_Status": "Статус",
  "key_PageDeviceInfo_FormTypeSelect_Info": "Инфо",
  "key_PageDeviceInfo_FormTypeSelect_System": "Системные",
  "key_PageDeviceInfo_FormTypeSelect_Error": "Ошибка",
  "key_PageDeviceInfo_FormStatusButton_Name": "Выбрать",
  "key_PageDeviceInfo_FormStatusButton_Apply": "Применить",
  "key_PageDeviceInfo_ButtonExportToExcel": "Экспорт в Excel",

  // -- DevicePartialSettings
  "key_DevicePartialSettings_Title": "Настройки",
  "key_DevicePartialSettings_GeneralSettings": "Общие настройки",
  "key_DevicePartialSettings_SecuritySettings": "Настройки безопасности",
  "key_DevicePartialSettings_DeleteDevice": "Удалить устройство",
  "key_DevicePartialSettings_GeneralSettingsHeader": "Основные настройки",
  "key_DevicePartialSettings_ModemInfoHeader": "Информация о модемах",
  "key_DevicePartialSettings_MediaSettingsHeader": "Список файлов",
  "key_DevicePartialSettings_TimeZoneLabel": "Часовой пояс",
  "key_DevicePartialSettings_TvControlLabel": "Управление TV",
  "key_DevicePartialSettings_CameraControlLabel": "Управление камерой",
  "key_DevicePartialSettings_ThisActionCannotBeUndone": "Это действие не может быть отменено. Это навсегда удалит устройство '%{name}'.",


  // -- DeviceEventCardList
  "key_DeviceEventCardList_TableHeaderDate": " Дата",
  "key_DeviceEventCardList_TableHeaderStatus": "Статус",
  "key_DeviceEventCardList_TableHeaderType": "Тип",
  "key_DeviceEventCardList_TableHeaderDescription": "Описание",

  // -- key_PageSettingsInfo
  "key_PageSettingsInfo_TabGeneral": "Инфо",
  "key_PageSettingsInfo_TabTags": "Теги",
  "key_PageSettingsInfo_TabSmtp": "Почтовый сервер",
  "key_PageSettingsInfo_TabTelegram": " Telegram",
  "key_PageSettingsInfo_TabVpn": "Vpn сервера",
  "key_PageSettingsInfo_Rules": "Правила",
  "key_PageSettingsInfo_FfmpegCommands": "Команды FFmpeg",

  // -- DeviceFormRemove
  "key_DeviceFormRemove_DeviceSerialName": "Серийный номер устройства",
  "key_DeviceFormRemove_DeviceName": "Имя устройства",
  "key_DeviceFormRemove_PleaseType": "Пожалуйста, введите имя устройства для подтверждения.",

  // -- DeviceFormTvControlUpdate
  "key_DeviceFormTvControlUpdate_TvEnable": "Включить управление телевизором",
  "key_DeviceFormTvControlUpdate_DelayScreenOffLabel": "Продолжительность работы экрана после отсутствия движения (в секундах)",
  "key_DeviceFormTvControlUpdate_MacAddressLabel": "Mac адрес",
  "key_DeviceFormTvControlUpdate_ScreenIPLabel": "Список IP для управления экранами (Если не задано, то по умолчанию используется 10.0.0.200)",
  "key_DeviceFormTvControlUpdate_ScreenControlLabel": "Управление экраном",
  "key_DeviceFormTvControlUpdate_ScreenTurnOnSchedule": "Включение по расписанию",
  "key_DeviceFormTvControlUpdate_ScreenTurnOnMotionDetect": "Включение по движению",
  "key_DeviceFormTvControlUpdate_ScreenTurnOnManual": "Ручное управление",
  "key_DeviceFormTvControlUpdate_ScreenTurnOnSwitchDescription": "Включить экран",
  "key_DeviceFormTvControlUpdate_ScreenTurnOnButton": "Включить экран",
  "key_DeviceFormTvControlUpdate_RemoteTvControlLabel": "Управление пультом",
  "key_DeviceFormTvControlUpdate_RemoteTvControlSwitchDescription": "Включить управление пультом",
  "key_DeviceFormTvControlUpdate_VolumeControlLabel": "Управление звуком",
  "key_DeviceFormTvControlUpdate_VolumeSwitchDescription": "Включить звук",
  "key_DeviceFormTvControlUpdate_InputSourceControlLock": "Входной сигнал",
  "key_DeviceFormTvControlUpdate_DateTimeMo": "Пн",
  "key_DeviceFormTvControlUpdate_DateTimeTu": "Вт",
  "key_DeviceFormTvControlUpdate_DateTimeWe": "Ср",
  "key_DeviceFormTvControlUpdate_DateTimeTh": "Чт",
  "key_DeviceFormTvControlUpdate_DateTimeFr": "Пт",
  "key_DeviceFormTvControlUpdate_DateTimeSa": "Сб",
  "key_DeviceFormTvControlUpdate_DateTimeSu": "Вс",

  // -- RangePickerForm
  "key_RangePickerForm_StartPlaceholder": "Старт",
  "key_RangePickerForm_EndPlaceholder": "Стоп",

  // -- MediaFileCardList
  "key_MediaFileCardList_TableHeaderStatus": "Статус",
  "key_MediaFileCardList_TableHeaderName": "Название",
  "key_MediaFileCardList_TableHeaderSize": "Размер",
  "key_MediaFileCardList_TableHeaderUpdated": "Создан",

  // -- DeviceGeneralSettings
  "key_DeviceGeneralSettings_NameLabel": "Название",
  "key_DeviceGeneralSettings_SerialLabel": "Серийный номер",
  "key_DeviceGeneralSettings_AddressLabel": "Адрес",
  "key_DeviceGeneralSettings_OwnerLabel": "Владелец",
  "key_DeviceGeneralSettings_TimezoneLabel": "Часовой пояс",
  "key_DeviceGeneralSettings_ApplicationVersionLabel": "Версия приложения",
  "key_DeviceGeneralSettings_PlayerVersionLabel": "Версия плеера",
  "key_DeviceGeneralSettings_VpvIPLabel": "VPN IP",
  "key_DeviceGeneralSettings_CameraLabel": "Камера",
  "key_DeviceGeneralSettings_FilesLabel": "Загрузка файлов",
  "key_DeviceGeneralSettings_TvOrientationLabel": "Ориентация телевизора",
  "key_DeviceGeneralSettings_TvOrientationSelectOption-Vertical": "Вертикально",
  "key_DeviceGeneralSettings_TvOrientationSelectOption-Horizontal": "Горизонтально",
  "key_DeviceGeneralSettings_ContentRotateLabel": "Поворот контента",
  "key_DeviceGeneralSettings_ContentRotateSelectOption-0": "0° (По-умолчанию)",
  "key_DeviceGeneralSettings_ContentRotateSelectOption-90": "90° (Поворот направо)",
  "key_DeviceGeneralSettings_ContentRotateSelectOption-180": "180° (Переворот)",
  "key_DeviceGeneralSettings_ContentRotateSelectOption-270": "270° (Поворот налево)",

  // -- DeviceModemInfo
  "key_DeviceModemInfo_SimCardLabel": "Провайдер и номер sim-карты",
  "key_DeviceModemInfo_ModemLabel": "Модем %{text}",
  "key_DeviceModemInfo_ProviderPlaceholder": "Оператор",
  "key_DeviceModemInfo_PhonePlaceholder": "Номер телефона",
  "key_DeviceModemInfo_SimSerialPlaceholder": "Номер sim-карты",
  "key_DeviceModemInfo_MobileProviderOptionGroup": " Оператор",
  "key_DeviceModemInfo_MegafonMobileProviderOption": "Мегафон",
  "key_DeviceModemInfo_BeelineSimSerialPlaceholder": "Билайн",
  "key_DeviceModemInfo_Tele2SimSerialPlaceholder": "Теле2",
  "key_DeviceModemInfo_MtsSimSerialPlaceholder": "МТС",

  // -- CustomerFormGeneralInfo
  "key_CustomerFormGeneralInfo_FirstNameLabel": "Имя",
  "key_CustomerFormGeneralInfo_LastNameLabel": "Фамилия",
  "key_CustomerFormGeneralInfo_UsernameLabel": " Логин",
  "key_CustomerFormGeneralInfo_EmailLabel": "Email",
  "key_CustomerFormGeneralInfo_PhoneLabel": " Телефон",

  // -- CustomerFormOrganizationCreate
  "key_CustomerFormGeneralCreate_ProfileInfo": "Информация о пользователе",
  "key_CustomerFormGeneralCreate_OrganizationInfo": "Информация об организации",
  "key_CustomerFormGeneralCreate_BankInfo": "Информация о банке",
  "key_CustomerFormGeneralCreate_FirstNameLabel": "Имя",
  "key_CustomerFormGeneralCreate_LastNameLabel": "Фамилия",
  "key_CustomerFormGeneralCreate_UsernameLabel": " Логин",
  "key_CustomerFormGeneralCreate_EmailLabel": "Email",
  "key_CustomerFormGeneralCreate_PhoneLabel": " Телефон",
  "key_CustomerFormOrganizationCreate_ITNLabel": "ИНН",
  "key_CustomerFormOrganizationCreate_BankLabel": "Банк",
  "key_CustomerFormOrganizationCreate_IECLabel": "КПП",
  "key_CustomerFormOrganizationCreate_CurrentAccountLabel": "Расчётный счёт",
  "key_CustomerFormOrganizationCreate_PSRNLabel": "ОГРН",
  "key_CustomerFormOrganizationCreate_CorrespondingAccountLabel": "Корреспондирующий счёт",
  "key_CustomerFormOrganizationCreate_BICLabel": "БИК",
  "key_CustomerFormOrganizationCreate_NameLabel": "Название организации",
  "key_CustomerFormOrganizationCreate_ActualAddressLabel": "Фактический адрес",
  "key_CustomerFormOrganizationCreate_LegalAddressLabel": "Юридический адрес",
  "key_CustomerFormOrganizationCreate_CEOLabel": "Гениральный директор",

  // -- CustomerFormOrganizationInfo
  "key_CustomerFormOrganizationInfo_ITNLabel": "ИНН",
  "key_CustomerFormOrganizationInfo_BankLabel": "Банк",
  "key_CustomerFormOrganizationInfo_IECLabel": "КПП",
  "key_CustomerFormOrganizationInfo_CurrentAccountLabel": "Расчётный счёт",
  "key_CustomerFormOrganizationInfo_PSRNLabel": "ОГРН",
  "key_CustomerFormOrganizationInfo_CorrespondingAccountLabel": "Корреспондирующий счёт",
  "key_CustomerFormOrganizationInfo_BICLabel": "БИК",
  "key_CustomerFormOrganizationInfo_NameLabel": "Название организации",
  "key_CustomerFormOrganizationInfo_ActualAddressLabel": "Фактический адрес",
  "key_CustomerFormOrganizationInfo_LegalAddressLabel": "Юридический адрес",
  "key_CustomerFormOrganizationInfo_CEOLabel": "Гениральный директор",

  // -- PageCustomerList
  "key_PageCustomerList_ProfileInfo": "Профайл",
  "key_PageCustomerList_Password": "Управление паролем",
  "key_PageCustomerList_OrganizationInfo": "Информация об организации",

  // -- CustomerTransactionCardList
  "key_CustomerTransactionCardList_OrderNumber": "# Транзакции",
  "key_CustomerTransactionCardList_Date": "Дата",
  "key_CustomerTransactionCardList_Description": "Основание",
  "key_CustomerTransactionCardList_Amount": "Сумма (руб)",

  // -- PageCustomerTransactionInfo
  "key_PageCustomerTransactionInfo_AddNewTransaction": "Добавить новую транзакцию",
  "key_PageCustomerTransactionInfo_AmountLabel": "Сумма (руб)",
  "key_PageCustomerTransactionInfo_DescriptionLabel": "Основание",

  // -- CustomerAdCardList
  "key_CustomerAdCardList_Status": "Статус",
  "key_CustomerAdCardList_Name": "Название",
  "key_CustomerAdCardList_Created": "Создан",
  "key_CustomerAdCardList_Action": "Действие",
  "key_CustomerAdCardList_ModalRemoveAd": "Удалить эту рекламную кампанию",
  "key_CustomerAdCardList_ModalRemoveAdApprove": "После удаления рекламной кампании '%{name}', пути назад уже не будет.",
  "key_PageCustomerAdList_EventsList": "Список воспроизведений на устройствах",
  "key_PageCustomerAdList_FilterSearch": "Поиск по имени",
  "key_PageCustomerAdList_FilterStatusAll": "Показать все",
  "key_PageCustomerAdList_FilterStatusCreated": "Новые",
  "key_PageCustomerAdList_FilterStatusAccepted": "Принятые",
  "key_PageCustomerAdList_FilterStatusRejected": "Не принятые",
  "key_PageCustomerAdList_FilterStatusDone": "Выполненные",


  // -- CustomerMediaCardList
  "key_CustomerMediaCardList_Status": "Состояние",
  "key_CustomerMediaCardList_State": "Статус",
  "key_CustomerMediaCardList_Name": "Название",
  "key_CustomerMediaCardList_Size": "Размер",
  "key_CustomerMediaCardList_Created": "Создан",
  "key_CustomerMediaCardList_Action": "Действие",
  "key_CustomerMediaCardList_ModalModifyMedia": "Редактировать",
  "key_CustomerMediaCardList_ModalRemoveMedia": "Удалить этот файл",
  "key_CustomerMediaCardList_ModalRemoveMediaApprove": "После удаления файла '%{name}', пути назад уже не будет.",
  "key_PageCustomerMediaList_FilterSearch": "Поиск по имени",
  "key_PageCustomerMediaList_FilterStatusAll": "Показать все",
  "key_PageCustomerMediaList_FilterStatusCreated": "Новые",
  "key_PageCustomerMediaList_FilterStatusAccepted": "Принятые",
  "key_PageCustomerMediaList_FilterStatusRejected": "Не принятые",


  // -- PageCustomerAdInfo
  "key_CustomerAdCardInfo_NameTitle": "Название",
  "key_CustomerAdCardInfo_CreatedTitle": "Дата создания",
  "key_CustomerAdCardInfo_CustomerTitle": "Покупатель",
  "key_CustomerAdCardInfo_MediaTitle": "Медиа",
  "key_CustomerAdCardInfo_FormatTitle": "Формат",
  "key_CustomerAdCardInfo_Dates": "Даты показов",
  "key_CustomerAdCardInfo_StartDateTitle": "Дата начала",
  "key_CustomerAdCardInfo_EndDateTitle": "Дата окончания",
  "key_CustomerAdCardInfo_StatusTitle": "Статус",
  "key_CustomerAdCardInfo_TagsTitle": "Тэги",
  "key_CustomerAdCardInfo_CostTitle": "Стоимость (руб.)",
  "key_CustomerAdCardInfo_StatusCreated": "Новая",
  "key_CustomerAdCardInfo_StatusRejected": "Отказ",
  "key_CustomerAdCardInfo_StatusAccepted": "Принята",
  "key_CustomerAdCardInfo_StatusDone": "Выполнена",
  "key_CustomerAdCardInfo_FormatVertical": "Вертикальный",
  "key_CustomerAdCardInfo_FormatHorizontal": "Горизонтальный",
  "key_CustomerAdCardInfo_ThereAreNoTagsAvailable": "Нет доступных тегов.",
  "key_CustomerAdCardInfoFormUpdate_PlaybackSettingsTitle": "Настройка даты и времени воспроизведения контента",
  "key_CustomerAdCardInfoFormUpdate_LabelDateOrDates": "Дата",
  "key_AdvertisingCampaignFormUpdate_LabelTime": "Время",
  "key_CustomerAdCardInfoFormUpdate_LabelDateStart": "Старт",
  "key_CustomerAdCardInfoFormUpdate_FormDate_Today": "Сегодня",
  "key_CustomerAdCardInfoFormUpdate_FormDate_ThisMonth": "Этот месяц",
  "key_CustomerAdCardInfoFormUpdate_LabelDateEnd": "Конец",
  "key_CustomerAdCardInfoFormUpdate_LabelDateRange": "Период",

  // -- CustomerMediaCardInfo
  "key_CustomerMediaCardInfo_NameTitle": "Название",
  "key_CustomerMediaCardInfo_CreatedTitle": "Дата создания",
  "key_CustomerMediaCardInfo_SizeTitle": "Размер",
  "key_CustomerMediaCardInfo_CustomerTitle": "Покупатель",
  "key_CustomerMediaCardInfo_CodecTitle": "Кодек",
  "key_CustomerMediaCardInfo_ScreenTitle": "Разрешение",
  "key_CustomerMediaCardInfo_BitrateTitle": "Битрейт",
  "key_CustomerMediaCardInfo_DurationTitle": "Хронометраж",
  "key_CustomerMediaCardInfo_StateTitle": "Состояние",
  "key_CustomerMediaCardInfo_FormatTitle": "Формат",
  "key_CustomerMediaCardInfo_StatusTitle": "Статус",
  "key_CustomerMediaCardInfo_StatusCreated": "Новый",
  "key_CustomerMediaCardInfo_StatusRejected": "Отказ",
  "key_CustomerMediaCardInfo_StatusAccepted": "Принято",
  "key_CustomerMediaCardInfo_FormatVertical": "Вертикальный",
  "key_CustomerMediaCardInfo_FormatHorizontal": "Горизонтальный",

  // -- AccountFormSmtpServer
  "key_AccountFormSmtpServer_LabelHost": "Сервер (Пример: smtp.gmail.com:587)",
  "key_AccountFormSmtpServer_LabelLogin": "Логин",
  "key_AccountFormSmtpServer_LabelPassword": "Пароль",
  "key_AccountFormSmtpServer_LabelSenderName": "Имя отправителя",
  "key_AccountFormSmtpServer_LabelSenderEmail": "Почта отправителя",

  // -- AccountFormTelegram
  "key_AccountFormTelegram_Enable": "Включить telegram",
  "key_AccountFormTelegram_LabelAccessToken": "Токен",

  // -- SettingsPartialTagList
  "key_SettingsPartialTagList_AddTagGroupTitle": "Добавление группы",
  "key_SettingsPartialTagList_ListTitle": "Список тегов",
  "key_SettingsPartialTagList_MediaRemoveTagConfirm": "Вы уверены, что хотите удалить `%{name}` тэг?",
  "key_SettingsPartialTagList_MediaRemoveGroupConfirm": "Вы уверены, что хотите удалить `%{name}` группу тэгов?",
  "key_SettingsPartialTagList_GroupUseInMediaPlanList": "Данная группа тэгов используется в медиапланах",
  "key_SettingsPartialTagList_TagUseInMediaPlanList": "Данный тэг используется в медиапланах",
  "key_SettingsPartialTagList_GroupNotUseInMediaPlanList": "Данный группа тэгов не используется в медиапланах",
  "key_SettingsPartialTagList_TagNotUseInMediaPlanList": "Данный тэг не используется в медиапланах",
  "key_SettingsPartialTagList_TagUseByMediaPlan": "Медиа план: %{name} (Покупатель: %{owner})",
  "key_SettingsPartialTagList_GroupUseInCustomerList": "Данная группа тэгов используется у покупателей",
  "key_SettingsPartialTagList_TagUseInCustomerList": "Данный тэг используется у покупателей",
  "key_SettingsPartialTagList_GroupNotUseInCustomerList": "Данный группа тэгов не используется у покупателей",
  "key_SettingsPartialTagList_TagNotUseInCustomerList": "Данный тэг не используется у покупателей",
  "key_SettingsPartialTagList_TagUseByCustomer": "Покупатель: %{username} (%{email})",

  // -- SettingsPartialRuleList
  "key_SettingsPartialRuleList_ListTitle": "Список правил",

  // -- SettingsPartialFFmpegCommandList
  "key_SettingsPartialFFmpegCommandList_ListTitle": "Список ffmpeg правил",

  // -- RuleFormCreate
  "key_RuleFormCreate_NameLabel": "Название",
  "key_RuleFormCreate_PeriodLabel": "Период",
  "key_RuleFormCreate_EventLabel": "Эвент",
  "key_RuleFormCreate_ConditionLabel": "Условие",
  "key_RuleFormCreate_ValueLabel": "Значение",
  "key_RuleFormCreate_UnitLabel": "Единица",

  // -- TagGroupFormCreate
  "key_TagGroupFormCreate_NameLabel": "Название",

  // -- SettingsPartialSmtpServer
  "key_SettingsPartialSmtpServer_Title": "Настройка почтового сервера",

  // -- SettingsPartialTelegram
  "key_SettingsPartialTelegram_Title": "Настройка telegram бота",

  // -- SettingsPartialVpnList
  "key_SettingsPartialVpnList_VpnServerCreate": "Добавление vpn сервера",
  "key_SettingsPartialVpnList_VpnServerList": "Список vpn серверов",

  // -- VpnServerCardList
  "key_VpnServerCardList_TableHeaderDomain": "Domain",
  "key_VpnServerCardList_TableHeaderHostname": "Hostname",
  "key_VpnServerCardList_TableHeaderPublicIP": "Public IP",
  "key_VpnServerCardList_TableHeaderCreated": "Created",
  "key_VpnServerCardList_ModalRemoveVpnServer": "Удаление конфига VPN сервера",
  "key_VpnServerCardList_ModalRemoveVpnServerApprove": "После удаления конфига vpn сервера '%{name}', пути назад уже не будет.",

  // -- VpnServerCardList
  "key_FFmpegCommandCardList_TableHeaderName": "Название",
  "key_FFmpegCommandCardList_TableHeaderCommand": "Команда",
  "key_FFmpegCommandCardList_ModalRemoveFFmpegCommand": "Удаление ffmpeg команды",
  "key_FFmpegCommandCardList_ModalRemoveFFmpegCommandApprove": "После удаления ffmpeg команды '%{name}', пути назад уже не будет.",

  "key_FFmpegCommandFormCreate_Title": "Добавление ffmpeg команды",
  "key_FFmpegCommandFormUpdate_Title": "Обновление ffmpeg команды",
  "key_FFmpegCommandFormCreate_NameLabel": "Название",
  "key_FFmpegCommandFormCreate_CommandLabel": "Команда",

  // -- key_VpnServerFormCreate
  "key_VpnServerFormCreate_DomainLabel": "Domain",
  "key_VpnServerFormCreate_HostnameLabel": "Hostname",
  "key_VpnServerFormCreate_PublicIpLabel": "Public IP",
  "key_VpnServerFormCreate_SSLLabel": " Сертификаты",
  "key_VpnServerFormCreate_CaCertLabel": "CA certificate (ca.crt)",
  "key_VpnServerFormCreate_CaKeyLabel": "CA private key (ca.key)",
  "key_VpnServerFormCreate_ServerCertLabel": "Server certificate (server.cert)",
  "key_VpnServerFormCreate_ServerKeyLabel": "Server private key (server.key)",
  "key_VpnServerFormCreate_TaKeyLabel": "TA private key (ta.key)",

  // -- PartnerMpCardList
  "key_PartnerMpCardList_Status": "Статус",
  "key_PartnerMpCardList_Name": "Название",
  "key_PartnerMpCardList_Created": "Создан",
  "key_PartnerMpCardList_Action": "Действие",
  "key_PartnerMpCardList_ModalRemoveMp": "Удалить этот медиа план",
  "key_PartnerMpCardList_ModalRemoveMpApprove": "После удаления медиа плана '%{name}', пути назад уже не будет.",
  "key_PagePartnerMpList_EventsList": "Список воспроизведений на устройствах",

  // -- PagePartnerMpInfo
  "key_PartnerMpCardInfo_NameTitle": "Название",
  "key_PartnerMpCardInfo_CreatedTitle": "Дата создания",
  "key_PartnerMpCardInfo_PartnerTitle": "Продавец",
  "key_PartnerMpCardInfo_MediaTitle": "Медиа",
  "key_PartnerMpCardInfo_FormatTitle": "Формат",
  "key_PartnerMpCardInfo_Dates": "Даты показов",
  "key_PartnerMpCardInfo_StartDateTitle": "Дата начала",
  "key_PartnerMpCardInfo_EndDateTitle": "Дата окончания",
  "key_PartnerMpCardInfo_StatusTitle": "Статус",
  "key_PartnerMpCardInfo_CostTitle": "Стоимость (руб.)",
  "key_PartnerMpCardInfo_StatusCreated": "Новая",
  "key_PartnerMpCardInfo_StatusRejected": "Отказ",
  "key_PartnerMpCardInfo_StatusAccepted": "Принята",
  "key_PartnerMpCardInfo_StatusDone": "Выполнена",
  "key_PartnerMpCardInfo_FormatVertical": "Вертикальный",
  "key_PartnerMpCardInfo_FormatHorizontal": "Горизонтальный",

  // -- PartnerMediaCardList
  "key_PartnerMediaCardList_Status": "Состояние",
  "key_PartnerMediaCardList_State": "Статус",
  "key_PartnerMediaCardList_Name": "Название",
  "key_PartnerMediaCardList_Size": "Размер",
  "key_PartnerMediaCardList_Created": "Создан",
  "key_PartnerMediaCardList_Action": "Действие",
  "key_PartnerMediaCardList_ModalRemoveMedia": "Удалить этот файл",
  "key_PartnerMediaCardList_ModalRemoveMediaApprove": "После удаления файла '%{name}', пути назад уже не будет.",
  "key_PartnerMediaCardList_FilterSearch": "Поиск по имени",
  "key_PartnerMediaCardList_FilterStatusAll": "Показать все",
  "key_PartnerMediaCardList_FilterStatusCreated": "Новые",
  "key_PartnerMediaCardList_FilterStatusAccepted": "Принятые",
  "key_PartnerMediaCardList_FilterStatusRejected": "Не принятые",

  // -- PartnerMediaCardInfo
  "key_PartnerMediaCardInfo_NameTitle": "Название",
  "key_PartnerMediaCardInfo_CreatedTitle": "Дата создания",
  "key_PartnerMediaCardInfo_SizeTitle": "Размер",
  "key_PartnerMediaCardInfo_PartnerTitle": "Покупатель",
  "key_PartnerMediaCardInfo_CodecTitle": "Кодек",
  "key_PartnerMediaCardInfo_ScreenTitle": "Разрешение",
  "key_PartnerMediaCardInfo_BitrateTitle": "Битрейт",
  "key_PartnerMediaCardInfo_DurationTitle": "Хронометраж",
  "key_PartnerMediaCardInfo_StateTitle": "Состояние",
  "key_PartnerMediaCardInfo_FormatTitle": "Формат",
  "key_PartnerMediaCardInfo_StatusTitle": "Статус",
  "key_PartnerMediaCardInfo_StatusCreated": "Новый",
  "key_PartnerMediaCardInfo_StatusRejected": "Отказ",
  "key_PartnerMediaCardInfo_StatusAccepted": "Принято",
  "key_PartnerMediaCardInfo_FormatVertical": "Вертикальный",
  "key_PartnerMediaCardInfo_FormatHorizontal": "Горизонтальный",

  // -- PartnerFormGeneralInfo
  "key_PartnerFormGeneralInfo_FirstNameLabel": "Имя",
  "key_PartnerFormGeneralInfo_LastNameLabel": "Фамилия",
  "key_PartnerFormGeneralInfo_UsernameLabel": " Логин",
  "key_PartnerFormGeneralInfo_EmailLabel": "Email",
  "key_PartnerFormGeneralInfo_PhoneLabel": " Телефон",

  // -- PagePartnerInfo
  "key_PagePartnerInfo_ProfileInfo": "Профайл",
  "key_PagePartnerInfo_Password": "Управление паролем",

  // -- PartnerFormOrganizationCreate
  "key_PartnerFormGeneralCreate_ProfileInfo": "Информация о пользователе",
  "key_PartnerFormGeneralCreate_FirstNameLabel": "Имя",
  "key_PartnerFormGeneralCreate_LastNameLabel": "Фамилия",
  "key_PartnerFormGeneralCreate_UsernameLabel": " Логин",
  "key_PartnerFormGeneralCreate_EmailLabel": "Email",
  "key_PartnerFormGeneralCreate_PhoneLabel": " Телефон",

  // -- PageDeviceList
  "key_PageDeviceList_FilterAll": "Показать все",
  "key_PageDeviceList_FilterOnline": "Показать в сети",
  "key_PageDeviceList_FilterOffline": "Показать не в сети",

  // -- SystemTriggerFormUpdate
  "key_SystemTriggerFormUpdate_AddRule": "Добавить правило",
  "key_SystemTriggerFormUpdate_TriggerName": "Название",
  "key_SystemTriggerFormUpdate_Action": "Действие",
  "key_SystemTriggerFormUpdate_ActionNotify": "Уведомить",
  "key_SystemTriggerFormUpdate_ActionRestart": "Перезагрузить",
  "key_SystemTriggerFormUpdate_OperatorOr": "или",
  "key_SystemTriggerFormUpdate_OperatorAnd": "и",
  "key_SystemTriggerFormUpdate_Operator": "когда",
  "key_SystemTriggerFormUpdate_Event": "Эвент",
  "key_SystemTriggerFormUpdate_EventBrowser": "Браузер",
  "key_SystemTriggerFormUpdate_EventTv": "ТВ",
  "key_SystemTriggerFormUpdate_EventScreen": "Экран",
  "key_SystemTriggerFormUpdate_EventHdmi": "HDMI",
  "key_SystemTriggerFormUpdate_EventDevice": "Устройство",
  "key_SystemTriggerFormUpdate_EventModem": "Модем",
  "key_SystemTriggerFormUpdate_EventWebsocket": "Вебсокет",
  "key_SystemTriggerFormUpdate_Status": "Статус",
  "key_SystemTriggerFormUpdate_StatusActive": "Активный",
  "key_SystemTriggerFormUpdate_StatusInactive": "Неактивный",
  "key_SystemTriggerFormUpdate_StatusOnline": "Онлайн",
  "key_SystemTriggerFormUpdate_StatusOffline": "Оффлайн",
  "key_SystemTriggerFormUpdate_ConditionIf": "Если",
  "key_SystemTriggerFormUpdate_Condition": "Условие",
  "key_SystemTriggerFormUpdate_AddCondition": "Добавить условие",
  "key_SystemTriggerFormUpdate_ConditionGT": "Больше",
  "key_SystemTriggerFormUpdate_ConditionEQ": "Равно",
  "key_SystemTriggerFormUpdate_ConditionLT": "Меньше",
  "key_SystemTriggerFormUpdate_Value": "Значение",
  "key_SystemTriggerFormUpdate_TimeInMinute": "Время (в минутах)",
  "key_SystemTriggerFormUpdate_Provider": "Провайдер",
  "key_SystemTriggerFormUpdate_NotifyEmail": "Email адрес",
  "key_SystemTriggerFormUpdate_ProvideToMail": "на Email",
  "key_SystemTriggerFormUpdate_ProvideToTelegram": "в Telegram",
  "key_SystemTriggerFormUpdate_Entity": "Сущность",
  "key_SystemTriggerFormUpdate_EntityBrowser": "Браузер",
  "key_SystemTriggerFormUpdate_EntityDevice": "Устройство",
  "key_SystemTriggerFormUpdate_EntityModem": "Модем",

  // -- key_AdFormTagsStatisticsInfo
  "key_AdFormTagsStatisticsInfo_TagName": "Тэг",
  "key_AdFormTagsStatisticsInfo_AdImpressions": "Кол-во показов",
  "key_AdPageInto_TagsStatistics": "Статистика по тегам",
};
