import React from "react";

class DeviceFormCameraControlUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    return (
      <div>
        <div>
          Connected: {this.props.device.status?.camera_active ? "YES" : "NO"}
        </div>
        <div>
          Name: {this.props.device.status?.camera_name}
        </div>
      </div>
    )
  }
}

export default DeviceFormCameraControlUpdate;
