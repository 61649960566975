import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getMediaFiles} from "../selectors";
import {AddressSuggestionsByName, Button} from "../containers";

import "antd/dist/antd.min.css";

const dadata_key = window._env_.DADATA_KEY;

class DeviceGeneralSettings extends React.Component {

  constructor(props) {
    super(props);
    const data = props.device || {};
    this.state = {
      data: {
        name: data.meta.name || "",
        address: data.spec.address || "",
        tv_orientation: data.spec.tv_orientation || "horizontal",
        content_rotate: data.spec.content_rotate || "0deg",
      },
      error: {},
      pending: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "name":
      case "tv_orientation":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      case "content_rotate":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      case "address":
        patch = {data: {...this.state.data, [name]: value.address}};
        break;
      default:
    }

    this.setState(patch)
  }

  handleEvent = () => {
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad device_update_interval parameter") e.name = errors.message;

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    return (
      <div>

        <div className="form-group">
          <label htmlFor="name">{I18n.t('key_DeviceGeneralSettings_NameLabel')}</label>
          <input className="form-control" type="text" id="name" name="name"
                 onChange={this.handleChange}
                 value={this.state.data.name}/>
        </div>


        <div className="form-group">
          <label htmlFor="serial">{I18n.t('key_DeviceGeneralSettings_AddressLabel')}</label>
          <AddressSuggestionsByName id="address" name="address"
                                className={`form-control form-control-sm ${this.state.error.address ? 'is-invalid' : ''}`}
                                token={dadata_key}
                                value={this.state.data.address}
                                onChange={this.handleChange}/>
        </div>

        <div className="form-group">
          <label htmlFor="serial">{I18n.t('key_DeviceGeneralSettings_SerialLabel')}</label>
          <input type="text" className="form-control form-control-sm" id="serial"
                 disabled={true}
                 readOnly={true}
                 value={this.props.device.meta.serial}/>
        </div>

        <div className="form-group">
          <label htmlFor="timezone">{I18n.t('key_DeviceGeneralSettings_TimezoneLabel')}</label>
          <input type="text" className="form-control form-control-sm" id="timezone"
                 disabled={true}
                 value={!!this.props.device.spec.timezone ? this.props.device.spec.timezone.offset : ""}/>
        </div>

        <div className="form-group">
          <label htmlFor="partner">{I18n.t('key_DeviceGeneralSettings_OwnerLabel')}</label>
          <input type="text" className="form-control form-control-sm" id="partner"
                 disabled={true}
                 value={this.props.device.meta.owner}/>
        </div>

        <div className="form-group">
          <label htmlFor="tv_orientation">{I18n.t('key_DeviceGeneralSettings_TvOrientationLabel')}</label>
          <select className="form-control form-control-sm" id="tv_orientation"
                  name="tv_orientation"
                  value={this.state.data.tv_orientation}
                  onChange={this.handleChange}>
            <option value="vertical">{I18n.t('key_DeviceGeneralSettings_TvOrientationSelectOption-Vertical')}</option>
            <option value="horizontal">{I18n.t('key_DeviceGeneralSettings_TvOrientationSelectOption-Horizontal')}</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="content_rotate">{I18n.t('key_DeviceGeneralSettings_ContentRotateLabel')}</label>
          <select className="form-control form-control-sm" id="content_rotate"
                  name="content_rotate"
                  value={this.state.data.content_rotate}
                  onChange={this.handleChange}>
            <option value="0deg">{I18n.t('key_DeviceGeneralSettings_ContentRotateSelectOption-0')}</option>
            <option value="90deg">{I18n.t('key_DeviceGeneralSettings_ContentRotateSelectOption-90')}</option>
            <option value="180deg">{I18n.t('key_DeviceGeneralSettings_ContentRotateSelectOption-180')}</option>
            <option value="270deg">{I18n.t('key_DeviceGeneralSettings_ContentRotateSelectOption-270')}</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="application_version">{I18n.t('key_DeviceGeneralSettings_ApplicationVersionLabel')}</label>
          <input type="text" className="form-control form-control-sm" id="application_version"
                 disabled={true}
                 value={this.props.device.status.application_version}/>
        </div>

        <div className="form-group">
          <label htmlFor="player_version">{I18n.t('key_DeviceGeneralSettings_PlayerVersionLabel')}</label>
          <input type="text" className="form-control form-control-sm" id="player_version"
                 disabled={true}
                 value={this.props.device.status.player_version}/>
        </div>

        <div className="form-group">
          <label htmlFor="vpn_ip">{I18n.t('key_DeviceGeneralSettings_VpvIPLabel')}</label>
          <input type="text" className="form-control form-control-sm" id="vpn_ip"
                 disabled={true}
                 value={this.props.device.status.vpn_ip}/>
        </div>

        <div className="form-group">
          <label htmlFor="camera">{I18n.t('key_DeviceGeneralSettings_CameraLabel')}</label>
          <input type="text" className="form-control form-control-sm" id="camera"
                 disabled={true}
                 value={this.props.device.status?.camera_name}/>
        </div>

        <div className="text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  submit={this.handleSubmit}
                  disabled={this.handleDisabled()}
                  main={I18n.t('key_Update')}
          />
        </div>
      </div>
    )
  }
}

DeviceGeneralSettings.propTypes = {
  name: PropTypes.string,
  device: PropTypes.object,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  files: getMediaFiles(state),
});

export default withRouter(connect(mapStateToProps)(DeviceGeneralSettings));
