import {createSelector} from 'reselect';

const getAccountMap = (state, opts) => {
  const items = state.data.getIn(['entities', 'accounts']);
  if (!items) return null;

  return (!!opts && !!opts.filter)
    ? items.filter(item => item.get("username").indexOf(opts.filter) !== -1)
    : items;
};

const getMediaFileItemByUsername = (state, username) => {
  const items = getAccountMap(state);
  return !!items
    ? items.find(item => item.get('username') === username)
    : null
};

const getCurrentAccount = (state) => {
  const items = getAccountMap(state);
  return !!items
    ? items.find(item => item.get('username') === state.data.getIn(['current_account']))
    : null
};

export const getAccounts = createSelector(
  [getAccountMap],
  (item) => !!item ? item.toJS() : {}
);

export const getAccountByUsername = createSelector(
  [getMediaFileItemByUsername],
  (item) => !!item ? item.toJS() : null
);
export const getAccount = createSelector(
  [getCurrentAccount],
  (item) => !!item ? item.toJS() : {}
);
